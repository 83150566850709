import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Customers = ({
    customersAlt,
    customersTitle,
    customerLogos,
    customersButton,
}) => (
    <div className='CustomersWrapper'>
    <div className='CustomersContainer'>
        <div className='CustomersInner'>
            <h4 className='CustomersAlt'>{customersAlt}</h4>
            <h3 className='CustomersTitle'>{customersTitle}</h3>
            <div className='CustomerLogos'>
                {customerLogos.map(({ id: customerLogosId, gatsbyImageData }) =>
                    <div key={customerLogosId}>
                        <ul className='CustomerLogosUl'>
                            <li className='CustomerLogosLi'>
                                <GatsbyImage className='CustomerLogo' image={gatsbyImageData} alt=""/>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            {customersButton}
        </div>
    </div>
    </div>
);