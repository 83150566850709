import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/PageWrapper';
import { Hero } from '../components/Hero';
import MainButton from '../components/Button';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Navigator } from '../components/Navigator';

import { ArrowLongRightIcon } from '@heroicons/react/20/solid';

import { Services } from '../components/Services';
import FooterCta from '../components/FooterCta';
import { Unqiues } from '../components/Uniques';
import { Customers } from '../components/Customers';
import { Brands } from '../components/Brands';
import { About } from '../components/About';

const HomepageTemplate = ({
    data: {
        datoCmsHomepage: {
            seo,
            hero: [{
                heroTitle,
                heroSpan1,
                heroSpan2,
                heroSpan3,
                heroSpan4,
                heroSpan5,
                heroSubtitle,
                heroImage,
            }],
            features,
            servicesAlt,
            servicesTitle,
            servicesButton,
            productAlt, 
            productTitle,
            firstUniqueAlt, 
            firstUniqueTitle, 
            firstUniqueText, 
            customersAlt, 
            customersTitle, 
            customerLogos,
            secondUniqueAlt, 
            secondUniqueTitle, 
            secondUniqueText, 
            brandsAlt, 
            brandsTitle, 
            brandsText, 
            brandsLogosTop, 
            brandsLogosBottom,
        },
        datoCmsAbout: {
            aboutAlt,
            aboutTitle,
            aboutText,
            firstKeyfactIcon,
            firstKeyfactNumber,
            firstKeyfact,
            secondKeyfactIcon,
            secondKeyfactNumber,
            secondKeyfact,
            thirdKeyfactIcon,
            thirdKeyfactNumber,
            thirdKeyfact,
            fourthKeyfactIcon,
            fourthKeyfactNumber,
            fourthKeyfact,
            aboutTeamPicture,
            aboutBarPicture1,
            aboutBarPicture2,
            aboutBarPicture3,
        },
        allDatoCmsServiceRecord: { serviceRecordNodes },
        allDatoCmsProduct: { productNodes },
        datoCmsServiceRoot: { 'id': servicePageId },
        datoCmsAboutpage: { 'id': aboutPageId},
        datoCmsContactpage: { 'id': contactPageId},
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
    >
        <Hero
            title={heroTitle}
            span1={heroSpan1}
            span2={heroSpan2}
            span3={heroSpan3}
            span4={heroSpan4}
            span5={heroSpan5}
            subtitle={heroSubtitle}
            image={heroImage}
            button={
                <MainButton recordId={contactPageId} className={'btn_wht'}>
                    Get in Touch
                </MainButton>
               
            }
        />
        {/*
        <div className='FeaturesContainer'>
            <div className='FeaturesWrapper'>
                {features.map(({ id: featureId, title, description }) => (
                    <div className='FeaturesInner' small key={featureId}>
                        <div className='FeaturesBlock'>
                            <h3 className='FeaturesTitle'>{title}</h3>
                            <text className='FeaturesText'>{description}</text>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        */}
        <Unqiues
            firstUniqueAlt={firstUniqueAlt}
            firstUniqueTitle={firstUniqueTitle}
            firstUniqueText={firstUniqueText}
            firstUniqueButton={
                <MainButton recordId={contactPageId} className={'btn_wht'}>
                    Let´s talk
                </MainButton>
            }
            secondUniqueAlt={secondUniqueAlt}
            secondUniqueTitle={secondUniqueTitle}
            secondUniqueText={secondUniqueText}
            secondUniqueButton={
                <MainButton recordId={contactPageId} className={'btn_wht'}>
                    Let´s talk
                </MainButton>
            }
        />
        <Customers
            customersAlt={customersAlt}
            customersTitle={customersTitle}
            customerLogos={customerLogos}    
            customersButton={
                <MainButton recordId={contactPageId} className={'btn_wht'}>
                    Be the next
                </MainButton>
            }    
        />

        <div className='HomeServicesWrapper'>
            <div className='HomeServicesInner'>
                <p className='HomeServicesAlt'>{servicesAlt}</p>
                <h3 className='HomeServicesTitle'>{servicesTitle}</h3>
                <MainButton recordId={servicePageId} className={'btn_wht'}>
                    Explore all Services
                </MainButton>
            </div>
                <div className='HomeServicesSwipperContainer'>
                    <ul className='HomeServicesSwipperWrapper'>
                        {serviceRecordNodes.map(({ id, level, title, subtitle, heroImage }) => (
                            <li className='HomeServicesSliderItem' small key={id}>
                                <Navigator recordId={id}>
                                    <div className='HomeServicesSliderImageContainer'></div>
                                    <GatsbyImage className='HomeServicesSliderImage' image={heroImage.gatsbyImageData} alt={heroImage.alt} />
                                    <div className='HomeServicesSliderTextWrapper'>
                                        <h3 className='HomeServicesSliderTitle'>{title}</h3>
                                        <text className='HomeServicesSliderText'>{subtitle}</text>
                                    </div>
                                </Navigator>
                            </li>
                        ))}
                    </ul>
                </div>
        </div>

        <Brands
            brandsAlt={brandsAlt}
            brandsTitle={brandsTitle}
            brandsText={brandsText}
            brandsLogosTop={brandsLogosTop}
            brandsLogosBottom={brandsLogosBottom}
            brandsButton={
                <MainButton recordId={contactPageId} className={'btn_wht'}>
                    Let´s talk
                </MainButton>
            }
        />
        
        <div className='HomeProductWrapper'>
            <div className='HomeProductInner'>
                <p className='HomeProductAlt'>{productAlt}</p>
                <h3 className='HomeProductTitle'>{productTitle}</h3>
            </div>
            <div className='HomeProductContainer'>
                <ul className='HomeProductItemWrapper'>
                    {productNodes.map(({ id, level, title, description, picture, price, priceText, count, countText }) => (
                            <li className='HomeProductItem' small key={id}>
                                <GatsbyImage className='HomeProductItemPicture' image={picture.gatsbyImageData} alt={picture.alt} />
                                <div className='HomeProductItemTextWrapper'>
                                    <div className='HomeProductItemTextContainer'>
                                        <h3 className='HomeProductItemTitle'>{title}</h3>
                                        <p className='HomeProductItemDescription'>{description}</p>
                                        <p className='HomeProductItemPrice'>{price} EUR {priceText} {count} {countText}</p>
                                    </div>
                                </div>
                            </li>
                    ))}
                </ul>
            </div>
        </div>

        <About 
            aboutAlt={aboutAlt}
            aboutTitle={aboutTitle}
            aboutText={aboutText}
            firstKeyfactIcon={firstKeyfactIcon}
            firstKeyfactNumber={firstKeyfactNumber}
            firstKeyfact={firstKeyfact}
            secondKeyfactIcon={secondKeyfactIcon}
            secondKeyfactNumber={secondKeyfactNumber}
            secondKeyfact={secondKeyfact}
            thirdKeyfactIcon={thirdKeyfactIcon}
            thirdKeyfactNumber={thirdKeyfactNumber}
            thirdKeyfact={thirdKeyfact}
            fourthKeyfactIcon={fourthKeyfactIcon}
            fourthKeyfactNumber={fourthKeyfactNumber}
            fourthKeyfact={fourthKeyfact}
            aboutTeamPicture={aboutTeamPicture}
            aboutButton={
                <MainButton recordId={aboutPageId} className={'btn_wht'}>
                    More about us
                </MainButton>
            }
        />
        
        <FooterCta />
        

    </PageWrapper>
);

export default HomepageTemplate;

export const query = graphql`
    query HomepageQuery($locale: String!) {
        datoCmsHomepage(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            hero {
                heroTitle
                heroSpan1
                heroSpan2
                heroSpan3
                heroSpan4
                heroSpan5
                heroSubtitle
                heroImage {
                    gatsbyImageData (
                        placeholder: BLURRED
                    )
                }
            }
            features {
                id: originalId
                title
                description
            }
            firstUniqueAlt
            firstUniqueTitle
            firstUniqueText
            secondUniqueAlt
            secondUniqueTitle
            secondUniqueText
            customersAlt
            customersTitle
            customerLogos {
                id: originalId
                gatsbyImageData (
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "200"
                        h: "200"
                    }
                )
            }
            brandsAlt
            brandsTitle
            brandsText
            brandsLogosTop {
                id: originalId
                gatsbyImageData (
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "200"
                        h: "200"
                    }
                )
            }
            brandsLogosBottom {
                id: originalId
                gatsbyImageData (
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "200"
                        h: "200"
                    }
                )
            }
            teamAlt
            teamTitle
            servicesAlt
            servicesTitle
            productAlt
            productTitle
        }
        datoCmsServiceRoot(locale: { eq: $locale }) {
            id: originalId
        }
        datoCmsContactpage(locale: { eq: $locale }) {
            id: originalId
        }
        datoCmsAboutpage(locale: { eq: $locale }) {
            id: originalId
        }
        allDatoCmsProduct(
            filter: {
                locale: { eq: $locale }
            }
            sort: {fields: level, order: ASC}
        ) {
        productNodes: nodes {
            locale
            level
            id: originalId
            title
            description
            picture {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "400"
                        h: "400"
                    }
                )
            }
            price
            priceText
            count
            countText
            }
        }

        allDatoCmsServiceRecord(
            filter: {
                locale: { eq: $locale }
            }
            sort: {fields: level, order: ASC}
        ) {
        serviceRecordNodes: nodes {
            locale
            level
            id: originalId
            title
            subtitle
            heroImage {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        fit: "crop"
                        w: "600"
                        h: "600"
                    }
                )
            }
        }}
        datoCmsAbout(locale: { eq: $locale }) {
            locale
            aboutAlt
            aboutTitle
            aboutText
            firstKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "150"
                        h: "150"
                    }
                )
            }
            firstKeyfactNumber
            firstKeyfact
            secondKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "150"
                        h: "150"
                    }
                )
            }
            secondKeyfactNumber
            secondKeyfact
            thirdKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "150"
                        h: "150"
                    }
                )
            }
            thirdKeyfactNumber
            thirdKeyfact
            fourthKeyfactIcon {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "150"
                        h: "150"
                    }
                )
            }
            fourthKeyfactNumber
            fourthKeyfact
            aboutTeamPicture {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { 
                        w: "800"
                        h: "600"
                    }
                )
            }
            aboutBarPicture1 {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            aboutBarPicture2 {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
            aboutBarPicture3 {
                gatsbyImageData(
                    placeholder: BLURRED
                    forceBlurhash: false
                    imgixParams: { invert: false }
                )
            }
        }
    }
`;