import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Brands = ({
    brandsAlt,
    brandsTitle,
    brandsText,
    brandsLogosTop,
    brandsLogosBottom,
    brandsButton,
}) => (
    <div className='BrandsWrapper'>
        <div className='BrandsInner'>
            <div className='BrandsInnerLeft'>
                <text className='BrandsAlt'>{brandsAlt}</text>
                <h3 className='BrandsTitle'>{brandsTitle}</h3>
            </div>
            <div className='BrandsInnerRight'>
                <p className='BrandsText'>{brandsText}</p>

            </div>
        </div>
        <div className='BrandsLogosTop'>
            {brandsLogosTop.map(({ id: brandsLogosTopId, gatsbyImageData }) => (
                <div key={brandsLogosTopId}>
                    <ul className='BrandsLogosUl BrandsLogosAnimationToLeft'>
                        <li className='BrandsLogosLi'>
                            <GatsbyImage className='BrandsLogos'  image={gatsbyImageData} alt="" />
                        </li>
                    </ul>
                </div>
            ))}
            
        </div>
        <div className='BrandsLogosBottom'>
            {brandsLogosBottom.map(({ id: brandsLogosBottomId, gatsbyImageData }) => (
                <div key={brandsLogosBottomId}>
                    <ul className='BrandsLogosUl BrandsLogosAnimationToRight'>
                        <li className='BrandsLogosLi'>
                            <GatsbyImage className='BrandsLogos'  image={gatsbyImageData} alt="" />
                        </li>
                    </ul>
                </div>
            ))}
        </div>
        <div className='BrandsInnerBottom'>
            {brandsButton}
        </div>
    </div>
);
