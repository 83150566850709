import React from 'react';
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import Button from './OldButton';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Unqiues = ({
    firstUniqueAlt,
    firstUniqueTitle,
    firstUniqueText,
    firstUniqueImage,
    firstUniqueButton,
    secondUniqueAlt,
    secondUniqueTitle,
    secondUniqueText,
    secondUniqueImage,
    secondUniqueButton,
}) => (
    <div className='HomeUniqueWrapper'>
        <p className='HomeUniqueAlt'>{firstUniqueAlt}</p>   
        <div className='HomeUniqueInner'>
            <div className='HomeUniqueContentWrapper HomeUniqueContentWrapper__left'>  
                <h3 className='HomeUniqueTitle'>{firstUniqueTitle}</h3>  
            </div>
            <div className='HomeUniqueContentWrapper HomeUniqueContentWrapper__right'>
                <p className='HomeUniqueText'>{firstUniqueText}</p>
            </div>
        </div>
        <div className='HomeUniqueInner HomeUniqueInner__bottom'>
            <div className='HomeUniqueContentWrapper HomeUniqueContentWrapper__left'>
                <p className='HomeUniqueText'>{secondUniqueText}</p>
            </div>
            <div className='HomeUniqueContentWrapper HomeUniqueContentWrapper__right'>   
                <h3 className='HomeUniqueTitle'>{secondUniqueTitle}</h3>
            </div>
        </div>
        <div className='HomeUniqueContentWrapperButton'>
            {secondUniqueButton}
        </div>
    </div>
    );


