import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export const Hero = ({
    title,
    span1,
    span2,
    span3,
    span4,
    span5,
    subtitle,
    button,
    image,
}) => (
    <div className="HeroWrapper">
        <div className='HeroInner'>
            <h1 className="HeroTitle">
                {title}
                <div className='HeroWordsWrapper'>
                <div className='HeroWords'>
                    <span className='HeroSpan'></span>
                    <span className='HeroSpan'>{span1}</span>
                    <span className='HeroSpan'>{span2}</span>
                    <span className='HeroSpan'>{span3}</span>
                    <span className='HeroSpan'>{span4}</span>
                    <span className='HeroSpan'>{span5}</span>
                </div>
                </div>
            </h1>
            <h2 className="HeroSubtitle">{subtitle}</h2>
            <div>{button}</div>
        </div>
        <div className='HeroImageWrapper'>
        <div className='HeroImageFilter'></div>
        <GatsbyImage image={image.gatsbyImageData} alt={image.alt} className="HeroImage"/>
        </div>        
    </div>
);